.app-bar-color {
    background-color: '#3f51b5';
}

.app-bar-height {
    height: 50px;
}

.app-bar-logout {
    padding-top: 10px;
    padding-right: 10px;
}