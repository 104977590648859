.drop-zone-box {
    border-radius: 10px;
    height: 5em;
    background-color: white;
}

.drop-zone-input {
    width: 100%; 
    height: 100%; 
    text-align: center;
}

.drop-zone-text {
    padding-top: 8px;
}

.container-button {
    height: 100%;
    padding-top: 20px;
}

.container-history-text {
    padding-bottom: 2em;
}

.no-uploads-box {
    height: 3em;
}

.error-space {
    padding-left: 10px;
}

.refresh-button {
    padding-left: 10px;
}

.refresh-animation {
    animation: refresh 1s infinite;
}

@keyframes refresh {
    0%   {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
}